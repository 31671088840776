var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-food-apple")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v("Ingredientes")]
            ),
            _vm._v(" /\n      Nuevo ingrediente\n    ")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.error$.message))
          ])
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-xs": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "pt-4": "", wrap: "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Nombre ingrediente",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "name", $$v)
                      },
                      expression: "ingredient.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.ingredientTypeOptions,
                      "item-text": "label",
                      "item-value": "value",
                      label: "Tipo de ingrediente",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.category,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "category", $$v)
                      },
                      expression: "ingredient.category"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-textarea", {
                    attrs: { outlined: "", name: "input-7-1", label: "Notas" },
                    model: {
                      value: _vm.ingredient.description,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "description", $$v)
                      },
                      expression: "ingredient.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Ingrediente substituto",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.substitute,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "substitute", $$v)
                      },
                      expression: "ingredient.substitute"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.allergens$,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Alérgenos",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.allergenId,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "allergenId", $$v)
                      },
                      expression: "ingredient.allergenId"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: [
                        { text: "Vegana", value: true },
                        { text: "Flexible", value: false }
                      ],
                      "item-text": "text",
                      "item-value": "value",
                      label: "Tipo de dieta",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.vegan,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "vegan", $$v)
                      },
                      expression: "ingredient.vegan"
                    }
                  })
                ],
                1
              ),
              _c("v-flex", { staticClass: "pb-2", attrs: { xs12: "" } }, [
                _c("strong", [_vm._v("Información nutricional")])
              ]),
              _c(
                "v-flex",
                { staticClass: "pr-2", attrs: { xs3: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Calorías",
                      type: "number",
                      step: "0.01",
                      min: "0.01",
                      max: "2000",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.kcal,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "kcal", $$v)
                      },
                      expression: "ingredient.kcal"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "px-2", attrs: { xs3: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Hidrato de Carbono",
                      type: "number",
                      step: "0.01",
                      min: "0.01",
                      max: "2000",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.carbs,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "carbs", $$v)
                      },
                      expression: "ingredient.carbs"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "px-2", attrs: { xs3: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Grasas",
                      type: "number",
                      step: "0.01",
                      min: "0.01",
                      max: "2000",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.fat,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "fat", $$v)
                      },
                      expression: "ingredient.fat"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "pl-2", attrs: { xs3: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Proteínas",
                      type: "number",
                      step: "0.01",
                      min: "0.01",
                      max: "2000",
                      outlined: ""
                    },
                    model: {
                      value: _vm.ingredient.protein,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "protein", $$v)
                      },
                      expression: "ingredient.protein"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    staticClass: "ma-2",
                    attrs: {
                      color: "primary",
                      label: "Mostrar en la lista de la compra"
                    },
                    model: {
                      value: _vm.ingredient.includeInShoppingList,
                      callback: function($$v) {
                        _vm.$set(_vm.ingredient, "includeInShoppingList", $$v)
                      },
                      expression: "ingredient.includeInShoppingList"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-white",
                  attrs: { color: "secondary", to: "/ingredientes" }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v("mdi-arrow-left")
                  ]),
                  _vm._v("Volver\n      ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { depressed: "", color: "success" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Guardar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }