import { render, staticRenderFns } from "./editar.vue?vue&type=template&id=67c79592&"
import script from "./editar.vue?vue&type=script&lang=js&"
export * from "./editar.vue?vue&type=script&lang=js&"
import style0 from "./editar.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VAppBar,VAppBarNavIcon,VBtn,VContainer,VFlex,VIcon,VLayout,VSelect,VSnackbar,VSwitch,VTextField,VTextarea,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/Users/mbernet/Sites/venu-sanz-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67c79592')) {
      api.createRecord('67c79592', component.options)
    } else {
      api.reload('67c79592', component.options)
    }
    module.hot.accept("./editar.vue?vue&type=template&id=67c79592&", function () {
      api.rerender('67c79592', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/ingredientes/editar.vue"
export default component.exports