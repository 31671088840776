<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-food-apple</v-icon>
      </div>
      <v-toolbar-title>
        <span style="color:#84264f; font-weight:bold">Ingredientes</span> /
        Nuevo ingrediente
      </v-toolbar-title>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-container fluid grid-list-xs>
      <v-layout pt-4 wrap align-center>
        <v-flex xs12>
          <v-text-field
            v-model="ingredient.name"
            background-color="white"
            label="Nombre ingrediente"
            outlined
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-select
            v-model="ingredient.category"
            :items="ingredientTypeOptions"
            item-text="label"
            item-value="value"
            label="Tipo de ingrediente"
            outlined
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-textarea
            v-model="ingredient.description"
            outlined
            name="input-7-1"
            label="Notas"
          ></v-textarea>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="ingredient.substitute"
            background-color="white"
            label="Ingrediente substituto"
            outlined
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-select
            v-model="ingredient.allergenId"
            :items="allergens$"
            item-text="name"
            item-value="id"
            label="Alérgenos"
            outlined
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-select
            v-model="ingredient.vegan"
            :items="[
              { text: 'Vegana', value: true },
              { text: 'Flexible', value: false },
            ]"
            item-text="text"
            item-value="value"
            label="Tipo de dieta"
            outlined
          ></v-select>
        </v-flex>

        <v-flex xs12 class="pb-2">
          <strong>Información nutricional</strong>
        </v-flex>

        <v-flex xs3 class="pr-2">
          <v-text-field
            v-model="ingredient.kcal"
            background-color="white"
            label="Calorías"
            type="number"
            step="0.01"
            min="0.01"
            max="2000"
            outlined
          ></v-text-field>
        </v-flex>

        <v-flex xs3 class="px-2">
          <v-text-field
            v-model="ingredient.carbs"
            background-color="white"
            label="Hidrato de Carbono"
            type="number"
            step="0.01"
            min="0.01"
            max="2000"
            outlined
          ></v-text-field>
        </v-flex>

        <v-flex xs3 class="px-2">
          <v-text-field
            v-model="ingredient.fat"
            background-color="white"
            label="Grasas"
            type="number"
            step="0.01"
            min="0.01"
            max="2000"
            outlined
          ></v-text-field>
        </v-flex>

        <v-flex xs3 class="pl-2">
          <v-text-field
            v-model="ingredient.protein"
            background-color="white"
            label="Proteínas"
            type="number"
            step="0.01"
            min="0.01"
            max="2000"
            outlined
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-switch
            color="primary"
            v-model="ingredient.includeInShoppingList"
            class="ma-2"
            label="Mostrar en la lista de la compra"
          ></v-switch>
        </v-flex>

        <v-btn class="text-white" color="secondary" to="/ingredientes">
          <v-icon left dark>mdi-arrow-left</v-icon>Volver
        </v-btn>
        <v-btn class="ml-4" depressed color="success" @click="save()"
          >Guardar</v-btn
        >
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import store from "../../store";
import { CATEGORY_OPTIONS } from "../../constants";

export default {
  data() {
    return {
      ingredientTypeOptions: CATEGORY_OPTIONS,
      ingredient: {
        allergenId: 0,
        name: "",
        type: "SIMPLE",
        description: "",
        substitute: "",
        category: CATEGORY_OPTIONS[0].value,
        includeInShoppingList: true,
        carbs: 0,
        kcal: 0,
        fat: 0,
        protein: 0,
        vegan: true,
      },
    };
  },
  computed: {
    allergens$() {
      const allergens = store.getters["ingredients/allergens"];
      allergens.push({ id: 0, name: "Ninguno" });
      allergens.sort((a, b) => a.id - b.id);
      return allergens;
    },
    error$() {
      return store.getters["ingredients/error"];
    },
  },
  methods: {
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    save() {
      if (!this.ingredient.name) {
        alert("Ingrediente no completo.");
        return;
      }
      this.$store
        .dispatch("ingredients/CreateNewIngredient", {
          ingredient: this.ingredient,
        })
        .then((data) => {
          if (data) this.$router.back();
        })
        .catch(() => {
          alert("No ha sido posible crear el ingrediente.");
        });
    },
  },
};
</script>

<style>
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: white !important;
}

.mb-2 {
  padding-bottom: 8px;
}

.ml-2 {
  padding-left: 8px;
}

.mr-2 {
  padding-right: 8px;
}

.mx-2 {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
